@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.container {
  background-color: #000d57;
  position: relative;
  bottom: 0rem;
  box-shadow: 0 0 0 100vw #000d57;
  max-width: 1400px;
  clip-path: inset(0 -100vw);
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 2.4rem;
  color: white;
  justify-content: space-between;
  padding: 2rem;
}

.section-one {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.logo {
  height: 6rem;
  margin-bottom: 1.6rem;
  /* width: 28.8rem; */
}

.info {
  color: #ffffff;
  text-align: left;
}

.info_main {
  color: #fff;
  text-align: center;
}

.categories {
  text-transform: uppercase;
  flex: 1;
}

.hr {
  display: none;
}

.h2 {
  font-size: 1rem;
  color: #fff;
  font-weight: 700;
  margin: 0;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.ul {
  font-size: 1rem;
  font-weight: 400;
  list-style: none;
  padding: 0;
  color: white;
}

.li {
  cursor: pointer;
  margin: 2rem 0;
  font-weight: normal;
}

.socials {
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.socials svg:hover {
  transition: 0.2s all ease-in-out;
}

.socials svg:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.search-container {
  width: min-content;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.login {
  padding: 1rem 0;
  width: 20rem;
  font-weight: 700;
  font-size: 1.4rem;
  background-color: #000d57;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 0.8rem;
  border: none;
  margin-top: 4.8rem;
  cursor: pointer;
  color: #fff;
}

.e_verify_logo {
  height: 3rem;
  background-color: #fff;
  border-radius: 0.2rem;
  margin-bottom: 1rem;
}

.copyright {
  background-color: #000833;
  color: white;
  position: relative;
  margin: 0 auto;
  padding: 2rem 1rem;
  box-shadow: 0 0 0 100vw #000d57;
  clip-path: inset(0 -100vw);
}

.company_link {
  text-decoration: underline;
  color: rgb(96, 120, 255);
}

.company_link:hover {
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .section-one {
    min-width: 20rem;
  }

  .categories {
    min-width: 20rem;
    border-top: 0.5px solid #fff;
    padding-top: 1rem;
  }

  .search-container {
    min-width: 20rem;
    display: flex;
    align-items: center;
    border-top: 0.5px solid #fff;
    padding-top: 1rem;
  }
}

@media only screen and (max-width: 385px) {
  .section-one {
    min-width: 15rem;
  }

  .categories {
    min-width: 15rem;
    border-top: 0.5px solid #fff;
    padding-top: 1rem;
  }

  .search-container {
    min-width: 15rem;
    display: flex;
    align-items: center;
    border-top: 0.5px solid #fff;
    padding-top: 1rem;
  }
}
