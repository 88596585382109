.careers_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.career_item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 1rem;
}

.careers_head {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.careers_head hr {
  height: 0.2rem;
  border-radius: 100rem;
  width: 8rem;
  background-color: #000d57;
  border: none;
}

.careers_head h1 {
  margin: 0;
}

.career_item p {
  text-align: left;
  margin-left: 2rem;
  line-height: 2.5rem;
  color: rgb(85, 85, 85);
}

.career_item summary {
  text-align: left;
  border: 0.1rem solid #000d57;
  border-radius: 0.5rem;
  padding: 1rem 1.4rem;
  font-weight: 500;
  color: #000d57;
}

@media only screen and (max-width: 695px) {
  .careers_main {
    padding: 0;
  }
  .careers_head {
    padding: 3rem 3rem 0 3rem;
  }
  .career_item {
    width: 90%;
  }
}

@media only screen and (max-width: 400px) {
  .careers_head {
    padding: 1rem;
  }
  .career_item {
    width: 90%;
  }
}
