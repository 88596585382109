.contact_main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 85vh;
}

.contact_main div {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
}

.div_contact {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 2rem;
  box-shadow: 0 0 20px rgba(180, 180, 180, 0.342);
  border-radius: 1rem;
}

.div_form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.contact_main div h1 {
  text-align: left;
  font-weight: 400;
}

.contact_main div a {
  color: #000;
  font-size: larger;
  font-weight: 500;
  margin-bottom: 1rem;
}

.contact_main div p {
  text-align: left;
}

.div_form label {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.div_form input {
  padding: 1rem;
  width: 90%;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 0.2rem;
}

.div_form textarea {
  padding: 1rem;
  min-width: 90%;
  max-width: 40vw;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 0.2rem;
}

.div_form button {
  background-color: #000d57;
  border: none;
  margin-top: 2rem;
  padding: 1rem 2rem;
  color: #fff;
  border-radius: 10rem;
  transition: 0.2s all ease-in-out;
  border: 2px solid #000d5700;
  margin-bottom: 1rem;
}

.contact_main div button:hover {
  background-color: #fff;
  border: 2px solid #000d57;
  color: #000d57;
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .contact_main div {
    padding: 0rem 2rem;
  }
}

@media only screen and (max-width: 480px) {
  .contact_main div {
    padding: 0 1rem;
  }

  .contact_main div h1 {
    font-size: 1.5rem;
  }

  .contact_main input {
    width: 85%;
  }

  .div_contact {
    margin: 1rem;
  }
}
