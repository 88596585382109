.services_content {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.service_items_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow: scroll;
}

.service_items_list div {
  box-shadow: 0px 0px 15px rgba(150, 150, 150, 0.15);
  border: 0.5px solid #eee;
  border-radius: 2rem;
  min-width: 250px;
  width: 25vw;
  max-width: 700px;
  margin: 15px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1.5rem;
  transition: 0.2s ease-in;
  -webkit-transition: 0.2s ease-in;
  top: 0;
  position: relative;
  border: 2px solid #ffffff00;
}

.service_items_list div:hover {
  transition: 0.6s ease-in;
  -webkit-transition: 0.3s ease-in;
  border: 2px solid #000d57;
  background-color: #3e53c92d;
  color: #fff;
}

.service_icon {
  width: 3rem;
  height: 3rem;
  background-color: #000d57;
  color: #fff;
  padding: 0.5rem;
  border-radius: 100%;
}

.service_items_list div h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  text-align: left;
  color: #191919;
  margin-top: 2rem;
}

.service_items_list div p {
  font-style: normal;
  text-align: left;
  font-weight: 500;
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.616);
  height: 5.2rem;
  overflow: hidden;
}

.service_items_list div button {
  background-color: #000d57;
  border: none;
  color: #fff;
  padding: 0.8rem 1rem;
  border-radius: 10rem;
}

.service_items_list div button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 996px) {
  .service_items_list div {
    max-width: auto;
    width: 80%;
  }
}

@media only screen and (max-width: 440px) {
  .service_items_list div {
    width: 70%;
  }
}
