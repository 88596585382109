.about_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 10%;
  text-align: left;
}

.about_main h1 {
  text-align: left;
  width: 100%;
  font-weight: 400;
}

.about_main p {
  line-height: 2.2rem;
}
