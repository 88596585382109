.carousel_home {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  z-index: 2;
  width: 100vw;
}

.carousel_home div {
  width: 100vw;
  height: 85vh;
  text-align: right;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  background-image: linear-gradient(to right, transparent, #000d57);
}

.carousel_home div h1 {
  font-size: 4rem;
  width: 50%;
  margin: 0 8rem;
}

.carousel_home div p {
  color: #fff;
  width: 40%;
  margin: 0 8rem;
}

@media only screen and (max-width: 916px) {
  .carousel_home div h1 {
    font-size: 3.5rem;
    width: 80%;
    margin: 0.5rem 2rem;
    text-align: center;
    align-self: center;
  }

  .carousel_home div p {
    width: 70%;
    font-size: 0.9rem;
    margin: 0 2rem;
    text-align: center;
    align-self: center;
  }

  .carousel_home div {
    background-image: linear-gradient(
      to right,
      transparent,
      #000d57c0,
      #000d57
    );
  }
}

@media only screen and (max-width: 505px) {
  .carousel_home div h1 {
    font-size: 2.8rem;
    width: 80%;
    margin: 0.5rem 2rem;
    text-align: center;
    align-self: center;
  }

  .carousel_home div p {
    width: 70%;
    margin: 0 2rem;
    font-size: 0.7rem;
    text-align: center;
    align-self: center;
  }
}

@media only screen and (max-width: 405px) {
  .carousel_home div h1 {
    font-size: 2.5rem;
    width: 80%;
    margin: 0.5rem 2rem;
    text-align: center;
    align-self: center;
  }

  .carousel_home div p {
    width: 70%;
    margin: 0 2rem;
    font-size: 0.7rem;
    text-align: center;
    align-self: center;
  }
}

@media only screen and (max-width: 362px) {
  .carousel_home div h1 {
    font-size: 2.2rem;
    width: 80%;
    margin: 0.5rem 2rem;
    text-align: center;
    align-self: center;
  }

  .carousel_home div p {
    width: 70%;
    margin: 0 2rem;
    font-size: 0.7rem;
    text-align: center;
    align-self: center;
  }
}
