.header_main {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 4rem;
  position: sticky;
  top: 0px;
  box-shadow: 0 0 10px #00000027;
  z-index: 3;
}

.header_main img {
  height: 5rem;
  object-fit: cover;
  width: 10rem;
}

.main_nav {
  display: flex;
  flex-direction: row;
}

.nav_item {
  text-decoration: none;
  color: #000d57b9;
  margin-right: 1.5rem;
  font-weight: 500;
  background-color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 100rem;
  /* border-top: 2px solid #000; */
}

.nav_item:hover {
  cursor: pointer;
}

.nav_item::after {
  content: "";
  height: 2px;
  left: 50%;
  display: block;
  background-color: #000d57;
  width: 0;
  transition: 0.3s all ease-in-out;
}

.nav_item:hover::after {
  width: 100%;
}

.nav_item_active {
  color: #fff;
  text-decoration: none;
  margin-right: 1.5rem;
  font-weight: 500;
  border-bottom: 2px solid #000d57;
  background-color: #000d57;
  padding: 0.5rem 1rem;
  border-radius: 100rem;
}

.nav_item_active:hover {
  cursor: pointer;
}

.mobile_nav {
  display: none;
}

.mobile_nav_div {
  position: absolute;
  margin-top: -150vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 40vw;
  height: 100vh;
  background-color: #fff;
  top: 0px;
  right: 0px;
  bottom: 0px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.514);
  padding: 8%;
  transition: 0.2s all ease-in-out;
}

.mobile_nav_div_active {
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 40vw;
  height: 100vh;
  background-color: #fff;
  top: 0px;
  right: 0px;
  bottom: 0px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.514);
  padding: 8%;
  transition: 0.2s all ease-in-out;
}

@media only screen and (max-width: 980px) {
  .main_nav {
    display: none;
  }

  .mobile_nav {
    display: block;
  }

  .nav_item {
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
  }

  .nav_item_active {
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 600px) {
  .header_main {
    padding: 0.5rem 2rem;
  }
}

@media only screen and (max-width: 365px) {
  .nav_item {
    margin-bottom: 1.5rem;
    font-size: 1rem;
  }

  .nav_item_active {
    margin-bottom: 1.5rem;
    font-size: 1rem;
  }
}
