.home_main {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: scroll;
}

.home_main img {
  width: 100vw;
  height: 85vh;
  object-fit: cover;
  position: relative;
  z-index: 1;
}
