.clients_content {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  overflow: hidden;
}

.clients_div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  width: 90%;
}

.clients_div a {
  border: 1px solid #000;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin-bottom: 2rem;
}

.clients_div a img {
  height: 5rem;
  width: 15rem;
  object-fit: contain;
}

.clients_content p {
  line-height: 2.2rem;
  margin: 0 3rem;
  font-size: large;
}
