.pre_header_main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 4rem;
  background-color: black;
}

.pre_header_main a {
  color: #fff;
  text-decoration: none;
}

.pre_header_main a:hover {
  cursor: pointer;
  text-decoration-line: underline;
}

.pre_header_main div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pre_header_main div hr {
  margin: 0 0.5rem;
  height: 1rem;
}

.social_btn {
  margin-left: 0.5rem;
  transition: all 0.3s ease-in-out;
}

.social_btn:hover {
  cursor: pointer;
  transform: scale(1.2);
}

@media only screen and (max-width: 900px) {
  .pre_header_main {
    flex-direction: column;
    padding: 0.8rem 0;
  }

  .social_btn {
    margin-top: 0.5rem;
  }
}
